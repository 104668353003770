<template>
    <img alt="logo" :src="getImage" :style="getStyle" />
</template>

<script>
import { FILE_ICONS } from '../../utilities/FILE_ICONS';
export default {
    props: {
        extension: {
            type: String,
            default: "default"
        },
        width: {
            type: String,
            default: "2.5rem"
        }
    },
    computed: {
        getStyle() {
            return {
                width: this.width,
                cursor: "pointer"
            }
        },
        getImage() {
            return FILE_ICONS[this.extension] ?? FILE_ICONS.default;
        }
    }
};
</script>

<style>
</style>