<template>
    <div>
        <OverlayPanel ref="filter">
            asdkoaskdoaskdoaksdokasdokdsao
        </OverlayPanel>
        
        <DataTable
            v-model:expandedRows="expandedRows"
            ref="dt"
            :rowGroupMode="rowGroupMode"
            :groupRowsBy="groupRowsBy"
            :selectionMode="selectionMode"
            :editMode="'cell'"
            :resizableColumns="resizable"
            :columnResizeMode=columnResizeMode
            :exportFilename="exportFilename"
            :showGridlines="gridlines"
            :value="rows"
            v-model:selection="selected"
            v-model:contextMenuSelection="selectedContextMenu"
            @rowContextmenu="onRowContextMenu"
            :dataKey="dataKey"
            :paginator="true"
            :rows="10"
            @filter="getFilter"
            @row-click="select"
            @rowgroup-expand="onRowExpand"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25]"
            :currentPageReportTemplate="currentPageReportTemplate"
            responsiveLayout="scroll"
            v-model:filters="filters"
            :filterDisplay="rowsFilter ? 'row' : null"
            :globalFilterFields="globalFilterFields"
        >
            <template #header v-if="headerVisible">
                <Toolbar>
                    <template v-if="exportable" #start>
                        <Button @click="exportCSV" icon="pi pi-file" v-tooltip.top="'Descargar CSV'" class="mr-2" />
                        <Button @click="exportXLS" icon="pi pi-file-excel" v-tooltip.top="'Descargar XLS'" class="p-button-success mr-2" />
                        <Button @click="exportPDF" icon="pi pi-file-pdf" v-tooltip.top="'Descargar PDF'" class="p-button-danger mr-2" />
                    </template>
                    <template #end>
                        <MultiSelect v-if="showVisibleColumns" :modelValue="internalSelectedColumns" 
                        :options="columns" 
                        optionLabel="name" 
                        @update:modelValue="onToggle"
                        placeholder="Columnas Visibles" style="width: 15em; margin-right: 2em;">
                        <template #value>
                            Columnas Visibles
                        </template>    
                        </MultiSelect>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                        </span>
                    </template>
                </Toolbar>
            </template>
            <Column v-if="expandable" :expander="expandable" headerStyle="width: 3rem" />
            <Column v-if="selectionModeColumn == 'multiple' || selectionModeColumn == 'single'" :selectionMode="selectionModeColumn" headerStyle="width: 3em"></Column>
            <Column :showFilterMatchModes="false" 
                    :showFilterMenu="false" :showFilterOperator="false" v-for="header in visibleHeaders" :key="header.value" :field="header.value" :header="header.name" :sortable="sorteable" :style="{ fontSize: fontsize }">
                <template v-if="header.editable" #editor="{ data, field }">
                    <InputText v-model="data[field]" autofocus />
                </template>
                <template #body="slotProps">
                    <img v-if="header.img" :style="{
                        'width': header.img_width, 
                        'margin-right': '15px'
                    }" :src="`${publicPath}images/icons/datatable/${slotProps.data[header.img_field]}.${header.img_ext}`" />
                    <!-- <span class="p-column-title">{{ header.name }}</span> -->
                    <div :style="{ fontSize: fontsize }">
                        <!-- Custom, aqui solo aplica cuando se quiere renderizar un componente externo -->
                        <Chip @click="$emit('chip-clicked-' + header.value, slotProps.data)" v-if="header.component == 'chip'" :label="getValue(slotProps.data[header.value], header, slotProps.data)" :icon="header.component_options.icon" class="custom-chip" />
                        <span v-else v-html="getValue(slotProps.data[header.value], header, slotProps.data)"></span>
                    </div>
                </template>
                <template v-if="rowsFilter" #filter="{filterModel, filterCallback}">
                    <InputText v-model="filterModel.value" v-if="header.type == 'datetime' || header.type == 'date' || header.type == 'time'" :type="header.type" @input="filterCallback()" />
                    <InputText v-else v-model="filterModel.value"  @keydown.enter="filterCallback()" />
                </template>
                <template v-if="footerVisible" #footer>
                    {{ footers[header.name] }}
                </template>
            </Column>
            <Column :style="{ width: getActionRowWidth() + 'px' }" v-if="rowaction" style="align-items: end">
                <template #body="slotProps">
                    <Button v-if="rowview" icon="pi pi-eye" class="p-button-rounded p-button-warning p-mr-2" @click="view(slotProps.data)" />
                    <Button v-if="rowedit" icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="edit(slotProps.data)" />
                    <Button v-if="rowdelete" icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-2" @click="deleted(slotProps.data)" />
                    <slot name="custom-row-button" :slot-scope="slotProps.data"></slot>
                    <FileIcon v-for="file in files" @click="$emit(file, slotProps.data)" :key="file" :extension="file" />
                </template>
            </Column>
            <template #expansion="expansionProps">
                <DataTable @row-click="selectDetail" selectionMode="single" :dataKey="expansionKey" :value="expansionProps.data[rowsExpandable]" responsiveLayout="scroll">
                    <Column v-for="headerExp in headersExpandable" :key="headerExp.value" :showFilterMatchModes="false" 
                    :showFilterMenu="false" :showFilterOperator="false"
                    :field="headerExp.value" :header="headerExp.name" :style="{ fontSize: fontsize }">
                        <template #body="bodyProps">
                            <div :style="{ fontSize: fontsize }">
                                {{bodyProps.data[headerExp.value]}}
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </template>
            <template #groupheader="slotProps" v-if="rowGroupMode">
                <h5 :style="{ fontSize: fontsizegroup, textAlign: 'center' }">{{ slotProps.data[groupRowsBy] }}</h5>
                <div :style="{ fontSize: fontsizegroup, textAlign: 'center' }">{{ getSubheaderGrouped(slotProps.data[groupRowsBy]) }}</div>
            </template>
        </DataTable>
        <ContextMenu v-if="contextMenu" :model="menuModelInternal" ref="cm" />
    </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
var math = require('mathjs');
import { HEADER_TYPES } from '../../utilities/HEADER_TYPES';
import { FOOTER_FUNCTIONS } from '../../utilities/FOOTER_FUNCTIONS';
import { COLUMN_FORMULA } from '../../utilities/COLUMN_FORMULA';
import { exportXLS } from '../../utilities/EXCEL_UTILITIES';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import FileIcon from '../general/FileIcon.vue';
import { FilterService } from 'primevue/api';

FilterService.register("DATE", (value,filter) => {
    if (filter == null) {
        return true
    }
    else if (filter == ""){
        return true
    }
    else{
        value = value.includes("T") ? value.substring(0, value.length - 9) : value;
        return value == filter;
    }
});

export default {
    expose: ['set', 'exportXLS', 'exportPDF', 'exportCSV'],
    components: { FileIcon },
    props: {
        rowsFilter: {
            type: Boolean,
            default: true
        },
        showVisibleColumns: { 
            type: Boolean,
            default: true
        },
        expansionKey: {
            type: String,
            default: "id",
        },
        footerVisible: {
            type: Boolean,
            default: true,
        },
        exportable: {
            type: Boolean,
            default: true,
        },
        columnResizeMode: {
            type: String,
            default: "fit",
        },
        files: {
            type: Array,
            default: () => [],
        },
        name: {
            type: String,
            default: 'Reporte',
        },
        exportFilename: {
            type: String,
            default: 'Descarga',
        },
        currentPageReportTemplate: {
            type: String,
            default: 'Mostrando {first} a {last} de {totalRecords} resultados',
        },
        rowsExpandable: {
            type: String,
            default: '',
        },
        headerVisible: {
            type: Boolean,
            default: true,
        },
        headersExpandable: {
            type: Array,
            default: () => [],
        },
        fontsize: {
            type: String,
            default: 'small',
        },
        fontsizegroup: {
            type: String,
            default: 'small',
        },
        rowGroupMode: {
            type: String,
            default: null,
        },
        rowSubgroupMode: {
            type: String,
            default: null,
        },
        groupRowsBy: {
            type: String,
            default: null,
        },
        subgroupRowsBy: {
            type: String,
            default: null,
        },
        dataKey: {
            type: String,
            default: 'id',
        },
        headers: {
            type: Array,
            default: () => [],
        },
        rows: {
            type: Array,
            default: () => [],
        },
        actionRowWidth: {
            type: [Number, null],
            default: null,
        },
        expandable: {
            type: Boolean,
            default: false,
        },
        sortable: {
            type: Boolean,
            default: true,
        },
        delete: {
            type: Boolean,
            default: false,
        },
        gridlines: {
            type: Boolean,
            default: true,
        },
        resizable: {
            type: Boolean,
            default: false,
        },
        rowaction: {
            type: Boolean,
            default: false,
        },
        rowdelete: {
            type: Boolean,
            default: false,
        },
        rowedit: {
            type: Boolean,
            default: false,
        },
        rowview: {
            type: Boolean,
            default: false,
        },
        selectionMode: {
            type: String,
            default: null,
        },
        selectionModeColumn: {
            type: String,
            default: 'none',
        },
        modelValue: {
            type: Array,
        },
        contextMenu: {
            type: Boolean,
            default: false,
        },
        sorteable: {
            type: Boolean,
            default: false,
        },
        menuModel: {
            type: Array,
            default: () => [],
        },
        selectedColumns: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            filteredValue: [],
            filters: {},
            selected: this.modelValue,
            internalSelectedColumns: this.selectedColumns,
            columns: [],
            selectedContextMenu: null,
            expandedRows: [],
            globalFilterFields: [],
            footers: {},
            groupedValue: null
        };
    },
    watch: {
        filteredValue(newValue) { 
            let res_fun  = null;
            this.footers = {};
            this.visibleHeaders.forEach(header => {
                let my_rows = newValue ? newValue.map((x) => x) : [];
                if (header.formula) {
                //* Se necesita evaluar primero
                    my_rows.forEach(my_row => {
                        my_row[header.value] = COLUMN_FORMULA[header.formula] ? COLUMN_FORMULA[header.formula](header.expression, my_row, header) : my_row[header.value];
                    });
                    res_fun = FOOTER_FUNCTIONS[header.function] ? FOOTER_FUNCTIONS[header.function](my_rows, header.value) : null;
                }else 
                res_fun = FOOTER_FUNCTIONS[header.function] ? FOOTER_FUNCTIONS[header.function](my_rows, header.value) : null;
                let result = res_fun == null ? null : this.getValue(res_fun, header, null);
                this.footers[header.name] = result;
            });
            this.$emit("filtered-footer", this.footers);
        }, 
        rows(newValue) {
            if ((newValue ?? []).length == 0) {
                this.selected = [];
                this.filteredValue = [];
                console.log('esta solo we')
            }
        },
        modelValue(newValue) {
            this.selected = newValue;
        },
        selected(newValue) {
            this.$emit('selected-change', newValue);
        },
        selectedColumns(newValue) {
            this.internalSelectedColumns = newValue;
        },
        async headers(newValue) {
            this.columns = newValue.filter((x) => x.value != this.groupRowsBy);
            if (this.internalSelectedColumns.length == 0) 
                this.internalSelectedColumns = newValue.filter((x) => x.value != this.groupRowsBy);
            this.globalFilterFields = newValue.map(x => x.value);
            
            //Esto se cambia por header
            this.globalFilterFields.forEach(filter => {
                let header = newValue.find(x => x.value == filter);
                this.filters[filter] = {
                    value: null,
                    matchMode: header.type == "date" ? FilterMatchMode.DATE_IS : FilterMatchMode.CONTAINS
                }
            });
        }
    },
    methods: {
        getFilter(event) {
            this.filteredValue = event.filteredValue;
        },
        onToggle(value) {
            this.internalSelectedColumns = this.columns.filter(col => value.includes(col));
        },
        exportXLS() {
            exportXLS(this.filteredValue, this.visibleHeaders, this.exportFilename);
        },
        exportPDF() {
            const exportColumns = this.visibleHeaders.map((header) => ({ title: header.name, dataKey: header.value }));
            
            const doc = new jsPDF();
            doc.autoTable({
                columns: exportColumns,
                body: this.filteredValue,
                margin: { top: 35 },
                didDrawPage: () => {
                    doc.text(this.name, 20, 30);
                },
            });
            doc.save(this.exportFilename + '.pdf');
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        selectDetail(payload) {
            this.$emit('selected-detail', payload.data);
        },
        onRowExpand() {
            alert('Expanded');
        },
        getSubheaderGrouped(val) {
            if (this.rowSubgroupMode == 'SUM') {
                let sum = this.filteredValue.reduce((a, b) => {
                    if (b[this.groupRowsBy] == val) {
                        return math
                            .chain(a)
                            .add(b[this.subgroupRowsBy] ?? 0.0)
                            .done();
                    } else {
                        return a;
                    }
                }, 0);

                return Intl.NumberFormat('es-MX', {
                    style: 'currency',
                    currency: 'MXN',
                }).format(sum);
            } else return val;
        },
        onRowContextMenu(event) {
            if (this.contextMenu) this.$refs.cm.show(event.originalEvent);
        },
        getActionRowWidth() {
            if (this.actionRowWidth) 
                return this.actionRowWidth;
            else
                return this.rowdelete && this.rowedit && this.rowview ? 150 : (this.rowdelete && this.rowedit) || (this.rowdelete && this.rowview) ? 100 : 50;
        },
        set(data) {
            alert(data);
        },
        deleted(payload) {
            this.$emit('deleted', payload);
        },
        edit(payload) {
            this.$emit('edited', payload);
        },
        view(payload) {
            this.$emit('view', payload);
        },
        select(payload) {
            this.$emit('selected', payload.data);
        },
        getValue(value, header, row) {
            if (header.formula && row) value = COLUMN_FORMULA[header.formula] ? COLUMN_FORMULA[header.formula](header.expression, row, header) : value;
            return HEADER_TYPES[header.type] ? HEADER_TYPES[header.type](value) : value;
        },
    },
    computed: {
        visibleHeaders() {
            return this.headers
                    .filter((x) => x.value != this.groupRowsBy)
                    .filter((x) => this.internalSelectedColumns
                                        .find(col => col.name == x.name));
        },
        menuModelInternal() {
            return this.menuModel.map((x) => {
                return {
                    label: x.label,
                    icon: x.icon,
                    command: () => this.$emit(x.action, this.selectedContextMenu),
                };
            });
        }
    },
    mounted() { 
        this.columns = this.headers.filter((x) => x.value != this.groupRowsBy);
        this.internalSelectedColumns = this.headers.filter((x) => x.value != this.groupRowsBy);

        this.globalFilterFields = this.headers.map(x => x.value);
        /* 
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                representative: { value: null, matchMode: FilterMatchMode.IN },
                status: { value: null, matchMode: FilterMatchMode.EQUALS },
                verified: { value: null, matchMode: FilterMatchMode.EQUALS }*/
        this.globalFilterFields.forEach(filter => {
            let header = this.headers.find(x => x.value == filter);
            this.filters[filter] = {
                value: null,
                matchMode: header.type == "date" ? "DATE" : FilterMatchMode.CONTAINS
            }
        });
    },
    created() {
        this.filters = {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
    },
};
</script>

<style lang="scss" scoped>
.p-chip.custom-chip {
    background: var(--primary-color);
    color: var(--primary-color-text);
    cursor: pointer;
}
</style>
