//No contiene estilos!
//const xlsx = require('xlsx');
//Con esta nos ahorramos un chingo de dinero 
const xlsx = require('sheetjs-style');

import { saveAsExcelFile } from './General';
import { COLUMN_FORMULA } from "./COLUMN_FORMULA"

const EXCEL_HEADER_TYPES = {
    currency: (value) => value,
    upper: (value) => (value ?? "").toUpperCase(),
    decimal: (value) => value,
    percentage: (value) => parseFloat(value).toFixed(2)+"%",
    date: (value) => typeof value === 'string' ? new Date(value) : value,
    "date-time": (value) => typeof value === 'string' ? new Date(value) : value,
    time: (value) => typeof value === 'string' ? new Date(value) : value,
    boolean: (value) => value ? 'SI' : 'NO',
    link: (value) => "<a href=''>" + value + "<a>"
};

const EXCEL_COLUMNS = {
    '0' : 'A',
    '1' : 'B',
    '2' : 'C',
    '3' : 'D',
    '4' : 'E',
    '5' : 'F',
    '6' : 'G',
    '7' : 'H',
    '8' : 'I',
    '9' : 'J',
    '10' : 'K',
    '11' : 'L',
    '12' : 'M',
    '13' : 'N',
    '14' : 'O',
    '15' : 'P',
    '16' : 'Q',
    '17' : 'R',
    '18' : 'S',
    '19' : 'T',
    '20' : 'U',
    '21' : 'V',
    '22' : 'W',
    '23' : 'X',
    '24' : 'Y',
    '25' : 'Z'
}

const EXCEL_FORMATS = {
    "currency" : "$#,##0.00",
    "decimal" : "0.00",
    "date" : "dd-mmmm-yyyy",
    "date-time" : "dd-mmmm-yyyy hh:mm AM/PM",
    "time" : "hh:mm:ss AM/PM",
    "year" : "yyyy",
    "month" : "mmmm",
    "dat" : "dd"
}

function formatBox(worksheet, rows, header) {
    if (!EXCEL_FORMATS[header.type])
        return worksheet;

    let columnIndex = null;
    let keys = Object.keys(rows[0]);
    for (let i = 0; i < keys.length; i++) {
        const element = keys[i];
        columnIndex = columnIndex ? columnIndex : (element == header.name) ? i : null;    
    }
    if (columnIndex) {
        let excel_column = EXCEL_COLUMNS[columnIndex.toString()];
        let final_lenght = header.function ? rows.length + 2 : rows.length + 1;
        for (let i = 2; i < final_lenght; i++) {
            worksheet[excel_column + i].z = EXCEL_FORMATS[header.type];
        }
    }
    return worksheet;
}

const EXCEL_FOOTER_FUNCTIONS = {
    SUM: (worksheet, rows, header_name) => {
        //* La logica es simple, primero, debemos de contar en que lugar esta nuestra prop en un objeto del array
        //* siempre empezaremos en la fila "2", y terminaremos en la fila "length del array" + 1
        let columnIndex = null;
        let keys = Object.keys(rows[0]);
        for (let i = 0; i < keys.length; i++) {
            const element = keys[i];
            columnIndex = columnIndex ? columnIndex : (element == header_name) ? i : null;    
        }
        if (columnIndex) {
            let excel_column = EXCEL_COLUMNS[columnIndex.toString()];
            let excel_formulae = "_xlfn.SUM("+excel_column+"2:"+excel_column+(rows.length)+")";
            let sum_box = excel_column + (rows.length + 1);
            worksheet[sum_box] = { t: "s", f: excel_formulae, F: sum_box, D: 1 };
            //* Estilo
            worksheet[sum_box].s = {
                alignment: {
                    horizontal: "center"
                },
                font: {
                  name: 'Calibri',
                  sz: 12,
                  bold: true
                },
            }
        }
        return worksheet;
    }
  }

function getValue(value, header, row) {
    if (header.formula && row) value = COLUMN_FORMULA[header.formula] ? COLUMN_FORMULA[header.formula](header.expression, row, header) : value;
    return EXCEL_HEADER_TYPES[header.type] ? EXCEL_HEADER_TYPES[header.type](value) : value;
}

function calculateMaxWidth(rows, headers) {
    let response = [];
    headers.forEach(header => {
        let max_width = header.type == "date" ? 12 : rows.reduce((w, r) => Math.max(w, (getValue(r[header.value], header, r) ?? "").toString().length), 10);
        response.push({ wch: max_width })
    });
    return response;
}

function exportXLS(rows, headers, fileName) {
    if (rows.length == 0) 
        throw "No existen filas para exportar a excel"
    let exportedRows = [];
    let headerFontStyle = { 
        fill: {
            fgColor: {
                rgb: "FFFFAA00"
            }
        },
        font: {
          name: 'Calibri',
          sz: 12,
          bold: true
        },
      };
    rows.forEach(r => {
        let x = {};
        headers.forEach(h => {
            x[h.name] = getValue(r[h.value], h, r);
        });
        exportedRows.push(x);
    });
    let emptyRow = {};
    headers.forEach(h => {
        emptyRow[h.name] = "";
    });
    exportedRows.push(emptyRow);
    const worksheet = xlsx.utils.json_to_sheet(exportedRows);
    worksheet["!cols"] = calculateMaxWidth(rows, headers);
    headers.forEach((h, index) => {
        if (h.function) 
            EXCEL_FOOTER_FUNCTIONS[h.function](worksheet, exportedRows, h.name);
        formatBox(worksheet, exportedRows, h);
        //* Por cada header, ponemos el estilo
        worksheet[EXCEL_COLUMNS[index.toString()]+"1"].s = headerFontStyle;
    });
    const workbook = { Sheets: { REPORTE: worksheet }, SheetNames: ['REPORTE'] };
    const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcelFile(excelBuffer, fileName);
}

export { exportXLS } 