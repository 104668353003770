var math = require("mathjs");

export const FOOTER_FUNCTIONS = {
    SUM: (rows, header) => rows.reduce((a, b) => {
      let sum = math.round(a, 2);
      let val = math.round(b[header] ?? 0.00, 2);
      return math.chain(sum).add(val).done();
    }, 0),
    PROM: (rows, header) => {
      let total = rows.reduce((a, b) => {
        let sum = math.round(a, 2);
        let val = math.round(b[header] ?? 0.00, 2);
        return math.chain(sum).add(val).done();
      }, 0)
      return math.chain(total).divide(rows.length).done();
    },
    COUNT: (rows) => rows.length ?? 0.00,
    MIN: (rows, header) => math.min(rows.map(x => x[header] ?? 0.00)),
    MAX: (rows, header) => math.max(rows.map(x => x[header] ?? 0.00))
  }

