var math = require("mathjs");

export const COLUMN_FORMULA = {
    evaluate: (expression, scope) => math.evaluate(expression, scope),
    today_difference: (expression, scope) => 
    {
      const date1 = new Date(scope[expression]);
      return Math.round((date1.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)); 
    },
    boolean_express: (expression, scope, header) =>  eval("scope." + expression) ? header.true_res : header.false_res,
    color: (expression, scope, header) => "<span style='color: "+ eval("scope." + expression) +";'>" + scope[header.value] +"</span>",
    search: (expression, scope, header) => expression == "find" ? 
    header.data.find(x => x[header.data_key] == scope[header.data_search_key]) ?
      header.data.find(x => x[header.data_key] == scope[header.data_search_key])[header.data_value] ?? 'NOT_FOUND'  
      : 'NOT_FOUND'
      : 'NOT_AVAILABLE',
    concat: (expression, scope) => {
      let props = expression.split('+').map(x => {
        return !(x.includes("{") && x.includes("}")) ? x.replace(" ", "") : x.substring(1).slice(0, -1);
      });
      if (props.length > 0) {
        let result = "";
        props.forEach(prop => {
          if (prop.includes('{') && prop.includes('}')) {
            result += prop.substring(1).slice(0, -1);
          }else result += scope[prop];
        });
        return result;
      } else 
      return "N/D";
    } 
  }